import React, { useState } from "react";
import { fetcher } from "services/api.service";
import { Button, CircularProgress } from "@mui/material";
import useSWR from "swr";
import { Inventory } from "types/inventory";

// Printing in Mobile Safari is rough.  Printing via iframe can work, but the scale is not correct.
// Opening a new window and printing directly from there works, but the window will not close automatically.
// This function uses the latter approach and tries to close the window after a delay.
// Taken from:
// https://github.com/crabbly/Print.js/issues/44#issuecomment-1550317552
function printInWindowSafariMobileHack(url: string) {
  const printWindow = window.open(url);
  if (!printWindow) throw new Error("Failed to open print window");
  printWindow.onload = () => {
    // Note using a zero delay setTimeout here to workaround issue that even when putting
    // print() inside of onload iOS Safari will prompt that the page hasn't loaded completely before print was called
    printWindow.setTimeout(() => {
      printWindow.focus();
      printWindow.print();

      // Try to automatically close the window after print
      // This is iOS Safari specific behavior we're relying on here. Essentially, there's not a good callback
      // currently to detect that the print dialog closed because
      // 1) "afterprint" event is only supported if the top level page is HTML where as it's PDF likely in this case
      // 2) there doesn't appear to be any other events (like focus) that we could hook into to detect
      // This works in iOS because if you call window.close() while the print dialog is open nothing happens. This isn't
      // true to other browsers though. Because of this though we can just periodically try to close the window until it works.
      printWindow.setInterval(() => printWindow.close(), 300);
    }, 500);
  };
}

export function PrintLabelButton({
  inventory,
  onClick,
  children,
}: {
  onClick?: () => void;
  children: React.ReactNode;
  inventory: Inventory;
}) {
  const {
    data: labelData,
    isLoading,
    isValidating,
  } = useSWR(`/current_fulfillment_batch/item/shipping_label?id=${inventory.id}`, (url: string) =>
    fetcher<{ url: string }>(url).then(async (data) => {
      const response = await fetch(data.url);
      return URL.createObjectURL(await response.blob());
    }),
  );

  const [showContinue, setShowContinue] = useState(false);
  if (isLoading || isValidating) return <CircularProgress />;
  return (
    <>
      <Button
        onClick={() => {
          if (labelData) printInWindowSafariMobileHack(labelData);
          if (onClick) setShowContinue(true);
        }}
        disabled={!labelData}
      >
        {children}
      </Button>
      {showContinue && (
        <Button variant={"contained"} onClick={onClick}>
          Continue
        </Button>
      )}
    </>
  );
}
