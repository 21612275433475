import React, { useState } from "react";
import { Link, useNavigate, useRouteError } from "react-router-dom";
import { Box, Button, Paper, Stack, TextField, Typography } from "@mui/material";
import { QrCodeScanner } from "@mui/icons-material";
import { AxiosError } from "axios";

function isAxiosError(error: unknown): error is AxiosError {
  return typeof error === "object" && error !== null && "isAxiosError" in error;
}
export function ScanInventoryPage() {
  const [inventoryId, setInventoryId] = useState("");
  const error = useRouteError();

  const axiosError = isAxiosError(error) ? error : null;

  if (axiosError && axiosError?.response?.status !== 404) {
    throw axiosError;
  }

  const navigate = useNavigate();
  return (
    <Box component={Paper} variant="outlined" padding={4}>
      <Stack spacing={4}>
        <Typography fontSize="24px" fontWeight={600}>
          Scan Label
        </Typography>
        <TextField
          autoFocus
          placeholder="Scan or type label"
          variant="outlined"
          error={!!axiosError}
          helperText={axiosError && "Inventory not found"}
          InputProps={{
            startAdornment: <QrCodeScanner sx={{ mr: 1 }} />,
          }}
          value={inventoryId}
          onChange={(e) => setInventoryId(e.target.value)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              navigate(`/resale-photo-booth/${inventoryId}`);
            }
          }}
        />
        <Stack direction="row" spacing={2} justifyContent="end">
          <Button component={Link} to={"configuration"} size="small" variant="contained">
            Camera Settings
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}
