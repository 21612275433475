import { Blob } from "activestorage";
import { CircularProgress, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { Delete, Refresh, WarningRounded } from "@mui/icons-material";
import React, { useMemo, useState } from "react";
import useSWR, { SWRConfiguration } from "swr";
import { uploadFile } from "hooks/useDirectUpload";
import { ImageThumb } from "components/Form/DirectUpload/ImageThumb";

interface DirectUploadImageThumbProps {
  file: File;
  url: string;
  onBlobCreated: (blob: Blob) => void;
  onClickDelete: () => void;
}

const useDirectFileUpload = (file: File, options?: SWRConfiguration) => {
  const [progress, setProgress] = useState(0);
  const url = useMemo(() => URL.createObjectURL(file), [file]);
  const result = useSWR(
    `file-upload/${url}`,
    () => uploadFile(file, { service: "amazon_public", onProgress: setProgress }),
    { revalidateIfStale: false, revalidateOnReconnect: false, revalidateOnFocus: false, ...options },
  );
  return { ...result, progress, url };
};

export const DirectUploadImageThumb = ({ file, url, onBlobCreated, onClickDelete }: DirectUploadImageThumbProps) => {
  const { progress, error, mutate, isLoading } = useDirectFileUpload(file, { onSuccess: onBlobCreated });
  return (
    <ImageThumb
      src={url}
      filename={file.name}
      sx={{ border: error ? "1px solid red" : undefined }}
      overlay={
        error && !isLoading ? (
          <Stack gap={0.5} alignItems="center">
            <WarningRounded color={"error"} fontSize={"large"} />
            <Tooltip title={error.message} arrow>
              <Typography color="error">Unable to upload file</Typography>
            </Tooltip>
            <Stack direction={"row"} gap={1}>
              <Tooltip title={"Retry"} arrow>
                <IconButton onClick={() => mutate()}>
                  <Refresh fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Remove"} arrow>
                <IconButton onClick={() => document.startViewTransition(() => onClickDelete())}>
                  <Delete fontSize="small" />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        ) : (
          <CircularProgress
            value={progress}
            variant={"determinate"}
            sx={{ placeSelf: "center", alignSelf: "center" }}
          />
        )
      }
    />
  );
};
