import { Box, Button, Divider, Paper, Stack, Typography } from "@mui/material";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { Inventory } from "types/inventory";
import React, { useMemo } from "react";
import { RevivnApiForm } from "components/Form/RevivnApiForm";
import { SubmitAwareButton } from "components/Form/SubmitAwareButton";
import DirectUploadField from "components/Form/DirectUploadField";
import { useAlert } from "hooks/useAlert";

const photoOrder = [
  "hero",
  "keyboard",
  "front_edge",
  "right_edge",
  "left_edge",
  "top_face",
  "top_angle",
  "bottom_face",
  "back_edge",
];

export function ReviewPhotosPage() {
  const { sessionPhotos, inventory } = useOutletContext<{ sessionPhotos: File[]; inventory: Inventory }>();
  const navigate = useNavigate();
  const orderedPhotos = useMemo(
    () => photoOrder.map((key) => sessionPhotos.find((f) => f.name.includes(key))).filter((x): x is File => !!x),
    [sessionPhotos],
  );

  const { alertSuccess } = useAlert();
  if (sessionPhotos.length === 0) {
    navigate("/resale-photo-booth", { replace: true });
    return null;
  }

  return (
    <Box component={Paper} variant={"outlined"} padding={4}>
      <RevivnApiForm
        initialValues={{ inventory: { skip_state: true, skip_serial_number: true, skip_user_warehouse: true } }}
        action={`inventories/${inventory.id}`}
        method="PATCH"
        onSuccess={() => {
          alertSuccess("Photos uploaded successfully");
          navigate("/resale-photo-booth", { replace: true });
        }}
      >
        <Stack direction="column" spacing={4}>
          <Stack direction="column">
            <Typography fontSize="24px" fontWeight={600}>
              Review Photos
            </Typography>
            <Typography fontSize="14px" fontWeight={400}>
              Review all photos carefully, ensuring they are high quality, the laptop is clearly visible in all photos,
              photos are not blurry, and there are no blemishes or marks.
            </Typography>
          </Stack>
          <DirectUploadField
            reorderable={false}
            sx={{ ".dropzone": { display: "none" }, ".deleteAction": { display: "none" } }}
            name="inventory[resalePhotos]"
            service="amazon_public"
            accept={["image/jpeg"]}
            initialFiles={orderedPhotos}
            multiple
          />
          <Divider sx={{ my: 4 }} />

          <Stack direction="row" spacing={4} justifyContent="end">
            <Button component={Link} to=".." color="error" variant="text" reloadDocument>
              Restart
            </Button>

            <SubmitAwareButton type="submit" color="primary" variant="contained">
              Submit Photos
            </SubmitAwareButton>
          </Stack>
        </Stack>
      </RevivnApiForm>
    </Box>
  );
}
