import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormControlLabelProps,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import useSWR from "swr";
import { Link } from "react-router-dom";
import { useMediaDevices } from "pages/Layouts/PhotoBoothLayout";

const localStorageKey = "resale-photo-booth/device-map";

function getDeviceMapping(): MediaDeviceInfo[] {
  const mapping = localStorage.getItem(localStorageKey);
  return mapping ? JSON.parse(mapping) : [null, null, null, null, null];
}

export function useSavedCameraConfiguration() {
  const { data, mutate } = useSWR<MediaDeviceInfo[]>("photobooth-saved-camera-configuration", async () => {
    return getDeviceMapping();
  });

  const persistingMutate = (devices: MediaDeviceInfo[]) => {
    localStorage.setItem(localStorageKey, JSON.stringify(devices));
    mutate(devices);
  };

  return { savedCameraConfiguration: data ?? getDeviceMapping(), mutate: persistingMutate };
}

const SelectDevicePosition = ({
  index,
  FormLabelProps,
}: {
  index: number;
  FormLabelProps: Partial<FormControlLabelProps>;
}) => {
  const { mediaDevices } = useMediaDevices("videoinput");
  const { savedCameraConfiguration, mutate } = useSavedCameraConfiguration();

  return (
    <FormControlLabel
      {...FormLabelProps}
      control={
        <TextField
          select
          size="small"
          sx={{ width: 250 }}
          defaultValue=""
          onChange={(event) => {
            if (!mediaDevices) return;
            const device = mediaDevices.find(({ deviceId }) => deviceId === event.target.value);
            if (!device) return;
            const newConfig = [...savedCameraConfiguration];
            newConfig[index] = device;
            mutate(newConfig);
          }}
          value={savedCameraConfiguration[index]?.deviceId ?? ""}
        >
          {mediaDevices &&
            mediaDevices.map((device) => (
              <MenuItem key={device.deviceId} value={device.deviceId}>
                {device.deviceId.substring(0, 4).toUpperCase()}: {device.label}
              </MenuItem>
            ))}
        </TextField>
      }
      label={<Typography variant="h6">{FormLabelProps.label}</Typography>}
      labelPlacement="top"
    />
  );
};

export function ConfigurationPage() {
  return (
    <Box component={Paper} variant="outlined" padding={4}>
      <Stack spacing={4}>
        <Stack>
          <Typography fontSize="24px" fontWeight={600}>
            Camera Setup
          </Typography>
          <Typography fontSize="14px" fontWeight={400}>
            Configure the position of each camera. The configuration will be saved locally.
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
          <SelectDevicePosition index={0} FormLabelProps={{ label: "Front Camera" }} />
          <SelectDevicePosition index={1} FormLabelProps={{ label: "Left Camera" }} />
          <SelectDevicePosition index={2} FormLabelProps={{ label: "Right Camera" }} />
          <SelectDevicePosition index={3} FormLabelProps={{ label: "Top Camera" }} />
          <SelectDevicePosition index={4} FormLabelProps={{ label: "Hero Camera" }} />
        </Stack>
        <Divider />
        <Stack direction="row" spacing={2} justifyContent="end">
          <Button component={Link} to={"/resale-photo-booth"} sx={{ paddingX: 3 }} variant="contained">
            Save Configuration
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}
