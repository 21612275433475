import useSWR from "swr";
import { fetcher } from "services/api.service";

export const useFeatureFlag = function (name: string) {
  const { data, isLoading } = useSWR<{ enabled: boolean }>(`/feature_flags/${name}`, fetcher, {
    revalidateIfStale: false,
  });

  return { enabled: data?.enabled, isLoading };
};
