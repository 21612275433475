import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ReactDOM from "react-dom";
import "./assets/index.css";
import { LicenseInfo } from "@mui/x-data-grid-pro";
// Hooks
import { createTheme, StyledEngineProvider, ThemeProvider } from "@mui/material";

// Components
import { AlertProvider } from "hooks/useAlert";
import { ConfirmationProvider } from "hooks/useConfirmation";
import { AuthProvider } from "hooks/useAuth";
import React from "react";
import { App } from "App";

const licenseKey = process.env.REACT_APP_MUI_PRO || "";
LicenseInfo.setLicenseKey(licenseKey);

const dsn = process.env.REACT_APP_SENTRY_DSN;
if (dsn) {
  const rate = parseFloat(process.env.REACT_APP_SENTRY_SAMPLE_RATE || "1.0");
  Sentry.init({
    dsn,
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || "production",

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: rate,
  });
}
declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }

  interface PaletteOptions {
    neutral?: PaletteOptions["primary"];
  }
}
declare module "@mui/material/Radio" {
  interface RadioPropsColorOverrides {
    neutral: true;
  }
}

export const theme = createTheme({
  palette: {
    text: {
      primary: "#263238",
      secondary: "#666666",
    },
    primary: {
      main: "#0089BD",
      light: "#DDF3FC",
    },
    secondary: {
      main: "#F9A62B",
      dark: "#D58031",
    },
    neutral: {
      main: "#64748B",
    },
    error: {
      main: "#c62828",
    },
  },
  typography: {
    fontFamily: "Verdana",
    h6: {
      fontSize: 18,
      fontWeightMedium: 500,
      color: "#0089BD",
    },
    body1: {
      fontSize: 14,
      fontWeightRegular: 400,
    },
    body2: {
      fontSize: 14,
      fontWeightLight: 700,
    },
  },
  components: {
    MuiListItem: {
      styleOverrides: {
        button: {
          "&:hover": {
            background: "#DDF3FC",
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: "#C62828",
        },
        colorSecondary: {
          "&$checked": {
            color: "#C62828",
          },
        },
        track: {
          backgroundColor: "#C62828",
          "$checked$checked + &": {
            backgroundColor: "#C62828",
          },
        },
      },
    },
  },
});

ReactDOM.render(
  <div>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <AlertProvider>
          <ConfirmationProvider>
            <AuthProvider>
              <App />
            </AuthProvider>
          </ConfirmationProvider>
        </AlertProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </div>,
  document.getElementById("root"),
);
