import { Menu as MenuIcon } from "@mui/icons-material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import {
  Avatar,
  Box,
  CssBaseline,
  Drawer as MuiDrawer,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  styled,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import { ListItems } from "AppNav";
import useCurrentUser from "hooks/useCurrentUser";
import { ReactComponent as LogoIcon } from "images/logo.svg";
import { theme as appTheme } from "index";
import React, { useEffect, useState } from "react";
import { Outlet, useRouteError } from "react-router-dom";
import stringToColor from "utils/stringToColor";
import "assets/App.css";

const drawerWidth: number = 300;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
    }),
    [theme.breakpoints.down("lg")]: { top: `0 !important`, ...(!open && { width: 0 }) },
  },
}));

export function LoggedInLayout() {
  const isTabletView = useMediaQuery(appTheme.breakpoints.down("lg"));
  const [open, setOpen] = useState(!isTabletView);
  const { user, currentUserPermissions } = useCurrentUser();
  const error = useRouteError();

  function handleToggle() {
    setOpen((prev) => !prev);
  }

  useEffect(() => {
    setOpen(!isTabletView);
  }, [isTabletView, setOpen]);

  return (
    <Box>
      <CssBaseline />

      {isTabletView && (
        <Box sx={{ flexGrow: 1, position: "sticky", top: 0, zIndex: 10 }}>
          <AppBar variant="outlined" elevation={0} sx={{ bgcolor: "white", color: "#000" }}>
            <Toolbar>
              <IconButton
                color="primary"
                onClick={handleToggle}
                aria-label={open ? "Collapse Sidebar" : "Expand Sidebar"}
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        </Box>
      )}

      <Drawer variant="permanent" open={open} as="nav">
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: [1],
          }}
        >
          <LogoIcon />
          <IconButton color="primary" aria-label={open ? "Collapse Sidebar" : "Expand Sidebar"} onClick={handleToggle}>
            {open ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>
        </Toolbar>
        <Box
          height="max-height"
          maxHeight="max-height"
          display="flex"
          flex="1 0"
          flexDirection="column"
          justifyContent="space-between"
          overflow="hidden"
        >
          <Box overflow="scroll" sx={{ overflowX: "hidden" }}>
            <ListItems open={open} setOpen={setOpen} currentUserPermissions={currentUserPermissions} />
          </Box>
          {user && open && (
            <ListItem
              dense={!!user.warehouseName}
              sx={{
                backgroundColor: "background.paper",
                boxShadow: (theme) => `0 -8px 8px ${theme.palette.background.paper}`,
              }}
            >
              <ListItemAvatar>
                <Avatar sx={{ width: 32, height: 32, bgcolor: stringToColor(user.email) }}>
                  {user.email.charAt(0).toUpperCase()}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={user.email} secondary={user.warehouseName} />
            </ListItem>
          )}
        </Box>
      </Drawer>

      <Box
        component="main"
        sx={{
          backgroundColor: (theme) => (theme.palette.mode === "light" ? "#F9FBFD" : theme.palette.grey[900]),
          padding: 3,
          minHeight: "calc(100vh - 64px)",
          marginLeft: open ? `${drawerWidth}px` : 7,
          [appTheme.breakpoints.down("lg")]: {
            marginTop: "64px",
            marginLeft: 0,
            padding: 3,
          },
          [appTheme.breakpoints.down("sm")]: {
            padding: 2,
            minHeight: "calc(100vh - 58px)",
            marginTop: "58px",
          },
        }}
      >
        {!error && <Outlet />}
      </Box>
    </Box>
  );
}
