import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { api } from "services/api.service";
import TokenService from "services/token.service";

type AuthContextValue = {
  loggedIn: boolean;
  setLoggedIn: (loggedIn: boolean) => void;
};

export const AuthContext = createContext<AuthContextValue>({
  loggedIn: false,
  setLoggedIn: () => {},
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [loggedIn, setLoggedIn] = useState<boolean>(Boolean(TokenService.get()));

  const value = useMemo(
    () => ({
      loggedIn,
      setLoggedIn,
    }),
    [loggedIn],
  );

  useEffect(() => {
    const pathname = window.location.pathname;
    const search = window.location.search;
    if (!pathname.includes("accept-invitation")) {
      if (search.includes("jwt")) {
        TokenService.set(search.replace("?jwt=", ""));
        setLoggedIn(true);
      }
    }
  }, [loggedIn]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default function useAuth() {
  const { loggedIn, setLoggedIn } = useContext(AuthContext);

  const login = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      try {
        const {
          data: {
            session: { jwt: token },
          },
        } = await api.post("/sessions", { session: { email, password } });
        TokenService.set(token);
        setLoggedIn(true);
      } catch (error) {
        console.log(error);
      }
    },
    [setLoggedIn],
  );

  return {
    login,
    loggedIn,
  };
}
