import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from "react-router-dom";
import React from "react";
import LoginSso from "pages/LoginSso";
import Login from "pages/Login";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/login" element={<LoginSso />} />
      <Route path="/phoenix_login" element={<Login />} />
      <Route path="*" element={<Navigate replace={true} to="/login" />} />
    </Route>,
  ),
);
export function LoggedOutRouter() {
  return <RouterProvider router={router} />;
}
