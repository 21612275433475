import { useState } from "react";
import { Formik } from "formik";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import useAuth from "hooks/useAuth";

export default function Login() {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { login } = useAuth();

  const handleClickShowPassword = () => {
    setShowPassword((state) => !state);
  };

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      onSubmit={(values) => {
        return login(values);
      }}
    >
      {({ handleSubmit, isSubmitting, getFieldProps }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={{ maxWidth: "450px", mx: "auto" }}>
            <Box mt={4}>
              <div>
                <Box display="flex" flexDirection="column" gap={2} justifyContent="center">
                  <Box display={"flex"} justifyContent={"center"}>
                    <Typography variant="caption" fontSize={"18px"} color={"primary"} fontWeight={550}>
                      {"LOGIN"}
                    </Typography>
                  </Box>
                  <TextField
                    fullWidth
                    id="outlined-email"
                    type="email"
                    label="Email"
                    {...getFieldProps("email")}
                    autoFocus
                  />
                  <TextField
                    fullWidth
                    id="outlined-password"
                    type={showPassword ? "text" : "password"}
                    label="Password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    {...getFieldProps("password")}
                  />
                  <Button variant="outlined" type="submit" disabled={isSubmitting}>
                    Login
                  </Button>
                </Box>
              </div>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
}
