import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Inventory } from "types/inventory";
import { CaptureAngle, steps } from "components/Photobooth/photoBoothSteps";
import React, { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { Box, Button, Divider, List, ListItem, ListItemText, Paper, Stack, Typography } from "@mui/material";
import { useHotkeys } from "react-hotkeys-hook";
import { audioSources } from "constants/audio_sources";
import { WebcamComponent } from "components/WebcamComponent";
import { ArrowBack, CheckCircle } from "@mui/icons-material";
import { useSavedCameraConfiguration } from "pages/PhotoBooth/ConfigurationPage";

const onCaptureAudioElement = new Audio(audioSources.onCaptureReady);

export function CapturePhotosPage() {
  const { photoSet } = useParams();
  const photoSetNumber = Number(photoSet);
  const { inventory, takePhotos } = useOutletContext<{
    inventory?: Inventory;
    takePhotos: (angles: CaptureAngle[], filenames: string[]) => Promise<void>;
  }>();

  const videoElement = useRef<HTMLVideoElement>(null);
  const { savedCameraConfiguration } = useSavedCameraConfiguration();
  const stepConfig = steps[photoSetNumber - 1];
  const isLastStep = photoSetNumber === steps.length;

  const [capture, setCapture] = React.useState(false);

  const navigate = useNavigate();

  const handleCapture = useCallback(async () => {
    const { angles, filenames } = stepConfig;
    try {
      await onCaptureAudioElement.play();
    } catch (e) {
      console.error("Failed to play audio", e);
    }
    await takePhotos(angles, filenames);
    if (!isLastStep) {
      navigate(`../capture/${photoSetNumber + 1}`);
    } else {
      navigate("../review");
    }
  }, [isLastStep, navigate, photoSetNumber, stepConfig, takePhotos]);

  const captureFnRef = React.useRef(handleCapture);

  useLayoutEffect(() => {
    captureFnRef.current = handleCapture;
  }, [handleCapture]);

  useEffect(() => {
    let cancel = false;
    if (!capture) return;

    captureFnRef.current().then(() => {
      if (cancel) return;
      setCapture(false);
    });
    return function cleanup() {
      cancel = true;
    };
  }, [capture]);

  useHotkeys("mod+g", () => setCapture(true), {
    description: "Capture Photo / Confirm",
    enableOnFormTags: ["INPUT"],
    preventDefault: true,
  });

  useEffect(() => {
    if (videoElement.current) {
      videoElement.current.load();
      videoElement.current.play();
    }
  }, [stepConfig.gifSource]);

  if (!inventory || !stepConfig) return null;

  return (
    <Box component={Paper} variant="outlined" padding={4}>
      <Stack spacing={4}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography fontSize="24px" fontWeight={600}>
            Capture Photos
          </Typography>
          <Typography fontSize="14px" fontWeight={400}>
            (Batch {photoSet})
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Stack spacing={1} sx={{ flexGrow: 1, width: "50%" }}>
            <Typography fontSize="18px" fontWeight={600}>
              Position Laptop
            </Typography>
            <Typography fontSize="14px" fontWeight={400} color="#6e6e6e">
              Orient the laptop as pictured.
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "313px",
                backgroundSize: "cover",
                overflow: "hidden",
                bgcolor: "#FAFAFA",
              }}
            >
              <video autoPlay muted style={{ height: "100%", objectFit: "cover" }} ref={videoElement}>
                <source src={stepConfig.gifSource} type="video/webm" />
                Your browser does not support the video tag.
              </video>
            </Box>
          </Stack>

          {savedCameraConfiguration && <WebcamComponent device={savedCameraConfiguration[CaptureAngle.Top]} />}

          <Stack spacing={1} sx={{ flexGrow: 1, width: "50%" }}>
            <Typography fontSize="18px" fontWeight={600}>
              Check Alignment & Capture Photos
            </Typography>
            <Typography fontSize="14px" fontWeight={400} color="#6e6e6e" mb={3}>
              Confirm laptop position and orientation then press foot pedal to take photos.
            </Typography>

            <Stack direction="column" justifyContent="center" sx={{ height: "313px", bgcolor: "#FAFAFA" }}>
              <List sx={{ p: 2 }}>
                {stepConfig.photoCaptureCheck.map((item) => (
                  <ListItem key={item}>
                    <CheckCircle sx={{ fontSize: "48px", color: stepConfig.color }} />
                    <ListItemText primary={item} sx={{ ml: 2 }} />
                  </ListItem>
                ))}
              </List>
            </Stack>
          </Stack>
        </Stack>
        <Divider />
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Button
            component={Link}
            to={"/resale-photo-booth"}
            size="small"
            variant="outlined"
            color="primary"
            startIcon={<ArrowBack />}
          >
            Back
          </Button>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography fontSize="14px" fontWeight={400}>
              Press foot pedal
            </Typography>
            <Typography fontSize="14px" fontWeight={400}>
              or
            </Typography>
            <Button onClick={() => setCapture(true)} variant="contained">
              Capture Photos
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}
