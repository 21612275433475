import Breadcrumbs from "components/common/Breadcrumbs";
import { Box, darken, Stack, styled, Tooltip, Typography } from "@mui/material";
import { useMemo } from "react";
import { RevivnApiDataGrid } from "components/DataGrid/RevivnApiDataGrid";
import { dateColDef } from "components/DataGrid/columnDefs/dateColDef";
import { GridActionsColDef, GridColTypeDef, getGridDateOperators } from "@mui/x-data-grid-pro";
import { Link } from "react-router-dom";
import { GenerateReplacementRowAction } from "components/Fulfillment/GenerateReplacementRowAction";
import { ShipstationOrderItem } from "types/shipstationOrderItem";
import { ManualReplacementRowAction } from "components/Fulfillment/ManualReplacementRowAction";
import { useStateColOptions } from "components/DataGrid/columnDefs/useStateColOptions";
import { Warning } from "@mui/icons-material";
import { MarkAsPackedRowAction } from "components/Fulfillment/MarkAsPackedRowAction";
import { MarkAsHighPriorityRowAction } from "components/Fulfillment/MarkAsHighPriorityRowAction";
import { pluralize } from "utils/helpers";

const warehouseOptions = [
  { value: "New York", label: "NY" },
  { value: "San Francisco", label: "SF" },
];

function getEmojiForState(orderItem: ShipstationOrderItem) {
  switch (orderItem.state.toLowerCase()) {
    case "created":
      if (orderItem.inventoryItem === null) return "🔍";
      if (orderItem.addressVerifiedAt === null) return "⚠️";
      if (orderItem.lastAssignedUser === null) return "🕕";
      return "👷";
    case "skipped":
      return "🟡️";
    case "packed":
      return "📦";
    case "on_cart":
      return "🛒";
    case "cancelled":
      return "⛔️";
    default:
      return "";
  }
}

const itemStateColDef: GridColTypeDef = {
  renderCell: (params) => {
    const hasInventory = params.row.inventoryItem !== null;
    const color = params.value === "No inventory assigned" ? "gray" : "default";
    const isError = params.value === "Skipped";
    const isPacked = params.value === "Packed";
    const isManuallyPacked = isPacked && params.row.packedReason;
    const subStatus = !hasInventory
      ? "Looking for Inventory"
      : params.row.addressVerifiedAt === null
        ? "Address issue"
        : params.row.lastAssignedUser !== null
          ? "Fulfillment Started"
          : "Awaiting Batch";

    return (
      <Stack color={color} direction={"row"} gap={1}>
        <Typography>{getEmojiForState(params.row)}</Typography>
        {params.value} {params.value === "Open" && <Typography color="gray">({subStatus})</Typography>}
        {isError && (
          <Typography color="error">
            {params.row.failureState} {params.row.failureReason}
          </Typography>
        )}
        {isManuallyPacked && (
          <Tooltip title={`Packed by: ${params.row.packedBy}`} placement="top" disableInteractive={true}>
            <Typography color="gray" sx={{ textDecoration: "underline", textDecorationStyle: "dotted" }}>
              {params.row.packedReason}
            </Typography>
          </Tooltip>
        )}
      </Stack>
    );
  },
  sortable: false,
};

const warehouseColDef: GridColTypeDef = {
  type: "singleSelect",
  sortable: false,
  valueGetter: ({ value }) => warehouseOptions.find((option) => option.value === value)?.label ?? value,
  valueOptions: warehouseOptions,
};

const inventoryItemColDef: GridColTypeDef = {
  sortable: false,
  renderCell: (params) => {
    return <Link to={`/inventories/${params.value}`}>{params.value}</Link>;
  },
};

const ageColDef: GridColTypeDef = {
  sortable: true,
  filterable: false,
  renderCell: (params) => {
    if (!params.row.addressVerifiedAt) return null;
    const addressVerifiedAt = new Date(params.row.addressVerifiedAt);
    const now = new Date();
    const diffTime = Math.abs(now.getTime() - addressVerifiedAt.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
    if (diffHours < 48) return <Typography>{pluralize(diffHours, "hour", "hours")}</Typography>;
    const diffMinutes = Math.ceil(diffTime / (1000 * 60));
    if (diffMinutes < 60) return <Typography>{pluralize(diffMinutes, "minute", "minutes")}</Typography>;

    return <Typography>{diffDays} days</Typography>;
  },
};

const shipByDef: GridColTypeDef = {
  sortable: true,
  filterable: true,
  filterOperators: getGridDateOperators(),
  type: "date",
  renderCell: (params) => {
    if (!params.row.shipBy) {
      return "-";
    }

    return (
      <Tooltip title={new Date(params.row.shipByDate).toString()}>
        <Typography>{params.row.shipBy}</Typography>
      </Tooltip>
    );
  },
};

const actionColDef: GridActionsColDef<ShipstationOrderItem> = {
  field: "actions",
  sortable: false,
  pinnable: true,
  type: "actions",
  align: "right",
  getActions: (params) => [
    <GenerateReplacementRowAction row={params.row} />,
    <ManualReplacementRowAction row={params.row} />,
    <MarkAsPackedRowAction row={params.row} showInMenu={true} />,
    <MarkAsHighPriorityRowAction row={params.row} showInMenu={true} />,
  ],
};

const StyledDataGrid = styled(RevivnApiDataGrid)({
  "& .high-priority": {
    backgroundColor: "#ffcccc",
    "&:hover": {
      backgroundColor: darken("#ffcccc", 0.1),
    },
  },
});

const addressVerifiedAtColDef: GridColTypeDef = {
  ...dateColDef,
  renderCell: (params) => {
    if (!params.value)
      return (
        <Tooltip title={params.row.addressVerification} placement="top">
          <Stack alignItems="center" direction="row" gap={1}>
            Not Validated <Warning color="warning" fontSize="small" />
          </Stack>
        </Tooltip>
      );
    return dateColDef.renderCell?.(params);
  },
};

export const FulfillmentPage = () => {
  const stateColOptions = useStateColOptions("shipstation_order/item");
  const columns = useMemo(() => {
    return [
      { field: "id", headerName: "ID", width: 100 },
      { field: "age", headerName: "Age", width: 100, ...ageColDef },
      { field: "shipBy", headerName: "Ship By", width: 100, ...shipByDef },
      { field: "orderNumber", headerName: "Order Number", width: 150 },
      { field: "state", headerName: "State", width: 300, ...stateColOptions, ...itemStateColDef },
      { field: "addressVerifiedAt", headerName: "Address Validation", width: 150, ...addressVerifiedAtColDef },
      { field: "orderedAt", headerName: "Order Date", width: 150, ...dateColDef },
      { field: "sku", headerName: "Order SKU", width: 150, sortable: false },
      { field: "channelName", headerName: "Channel", width: 150 },
      { field: "lastAssignedUser", headerName: "Last User Assigned", width: 200 },
      { field: "warehouse", headerName: "Warehouse", ...warehouseColDef },
      { field: "inventoryItem", headerName: "Inventory Item", width: 150, ...inventoryItemColDef },
      { headerName: "Actions", width: 140, ...actionColDef },
    ];
  }, [stateColOptions]);
  const tenDaysAgo = new Date();
  tenDaysAgo.setDate(tenDaysAgo.getDate() - 10);
  const formattedDate = tenDaysAgo.toISOString().split("T")[0];
  return (
    <Stack>
      <Breadcrumbs data={[{ label: "Order Fulfillment" }]}></Breadcrumbs>
      <Box my={4}>
        <Typography variant="h4">Order Fulfillment</Typography>
      </Box>
      <StyledDataGrid
        url={`shipstation_order_items?filter%5B%5D=ordered_at%2Cafter%2C${formattedDate}`}
        columns={columns}
        getRowClassName={(params) => (params.row.state != "packed" && params.row.priority > 0 ? "high-priority" : "")}
      />
    </Stack>
  );
};
