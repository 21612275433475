import { Add, Autorenew, CheckCircle, Error, RemoveCircle } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { getGridSingleSelectOperators, GridColDef, GridRenderCellParams, GridToolbar } from "@mui/x-data-grid-pro";
import { dateTimeColDef } from "components/DataGrid/columnDefs/dateTimeColDef";
import { RevivnApiDataGrid } from "components/DataGrid/RevivnApiDataGrid";
import { Link } from "react-router-dom";
import { ProductCatalog } from "types/productCatalog";
import backmarketLogo from "./assets/backmarket_logo.png";
import ebayLogo from "./assets/ebay-logo.png";
import reebeloLogo from "./assets/reebelo-logo.png";

type GridRenderCellParamsCustomType = GridRenderCellParams<string, ProductCatalog, unknown>;
const toPrice = (value: string | number) => Number(value).toFixed(2);
const priceRenderer =
  (field: "startingPrice" | "ebayPrice" | "backmarketPrice" | "reebeloPrice") =>
  (params: GridRenderCellParamsCustomType) => (params.row[field] ? `$${toPrice(params.row[field])}` : "-");

type StatusType = "active" | "action_needed" | "not_listed" | "pending" | "invalid";

const merchantStatus: Record<string, { icon: JSX.Element; label: string }> = {
  active: {
    icon: <CheckCircle fontSize="small" color="success" />,
    label: "Active",
  },
  actionNeeded: {
    icon: <Error fontSize="small" color="warning" />,
    label: "Action Needed",
  },
  pending: {
    icon: <Autorenew fontSize="small" color="warning" />,
    label: "Pending",
  },
  notListed: {
    icon: <RemoveCircle fontSize="small" color="disabled" />,
    label: "Not Listed",
  },
  invalid: {
    icon: <RemoveCircle fontSize="small" color="disabled" />,
    label: "Invalid",
  },
};

const statusMapping: Record<StatusType | "not_listed", { icon: JSX.Element; label: string }> = {
  active: merchantStatus.active,
  action_needed: merchantStatus.actionNeeded,
  invalid: merchantStatus.invalid,
  pending: merchantStatus.pending,
  not_listed: merchantStatus.notListed,
};

const channelStatusRenderer =
  (field: "ebayStatus" | "backmarketStatus" | "reebeloStatus") => (params: GridRenderCellParamsCustomType) => {
    if (!params.row[field] && (field === "backmarketStatus" || field === "reebeloStatus")) {
      return "-";
    }

    const status = (params.row[field] as StatusType) || "not_listed";
    let icon, label;
    switch (field) {
      default:
        ({ icon, label } = statusMapping[status]);
        break;
    }

    return (
      <Stack direction="row">
        {icon}
        {label}
      </Stack>
    );
  };

export default function ProductCatalogsDataGrid() {
  const photosField = (productCatalog: ProductCatalog) => {
    const hasPhotos = productCatalog.hasStockPhotos || productCatalog.hasResalePhotos;

    if (!productCatalog.photosUrl) {
      return null;
    }

    return (
      <Link to={productCatalog.photosUrl} style={{ textDecoration: "none" }}>
        <Button size="small" variant="text" color="primary" startIcon={hasPhotos ? <CheckCircle /> : <Add />}>
          {hasPhotos ? "View Photos" : "Add Photos"}
        </Button>
      </Link>
    );
  };

  const statusFilters = {
    display: "flex",
    width: 140,
    filterOperators: getGridSingleSelectOperators(),
    valueOptions: [
      { value: "active", label: "Active" },
      { value: "action_needed", label: "Action Needed" },
      { value: "not_listed", label: "Not Listed" },
    ],
  };

  const columns: GridColDef<ProductCatalog>[] = [
    { field: "id", headerName: "Product ID", hide: true },
    { field: "merchantProductNumber", headerName: "Merchant Product Number" },
    { field: "quantity", headerName: "Quantity", type: "number" },
    { field: "sku", headerName: "SKU" },
    {
      field: "backmarketStatus",
      headerName: "BackMarket Status",
      renderHeader: () => <img src={backmarketLogo} style={{ width: "80%", height: "80%" }} />,
      renderCell: channelStatusRenderer("backmarketStatus"),
      ...statusFilters,
    },
    {
      field: "ebayStatus",
      headerName: "Ebay Status",
      renderHeader: () => <img src={ebayLogo} style={{ width: "50%", height: "50%" }} />,
      renderCell: channelStatusRenderer("ebayStatus"),
      ...statusFilters,
    },
    {
      field: "reebeloStatus",
      headerName: "Reebelo Status",
      renderHeader: () => <img src={reebeloLogo} style={{ width: "50%", height: "50%" }} />,
      renderCell: channelStatusRenderer("reebeloStatus"),
      ...statusFilters,
      valueOptions: [
        { value: "active", label: "Active" },
        { value: "not_listed", label: "Not Listed" },
      ],
    },
    { field: "inventoryId", headerName: "Phoenix ID" },
    { field: "deviceType", headerName: "Device Type" },
    { field: "inventoryGroup", headerName: "Inventory Group" },
    { field: "backmarketId", headerName: "Backmarket ID" },
    { field: "reebeloId", headerName: "Reebelo ID" },
    {
      field: "ebayPriceInCents",
      headerName: "Ebay Price",
      renderCell: priceRenderer("ebayPrice"),
      type: "number",
    },
    {
      field: "startingPriceInCents", // TODO: Remove field
      headerName: "Starting Price",
      renderCell: priceRenderer("startingPrice"),
      type: "number",
    },
    {
      field: "backmarketPriceInCents",
      headerName: "Backmarket Price",
      renderCell: priceRenderer("backmarketPrice"),
      type: "number",
    },
    {
      field: "reebeloPriceInCents",
      headerName: "Reebelo Price",
      renderCell: priceRenderer("reebeloPrice"),
      type: "number",
    },
    { field: "title", headerName: "Title" },
    { field: "subtitle", headerName: "Subtitle", hide: true, filterable: false },
    { field: "description", headerName: "Description", hide: true, filterable: false },
    { field: "categoryId", headerName: "Category ID", hide: true, filterable: false },
    { field: "primaryCategory", headerName: "Primary Category", hide: true, filterable: false },
    { field: "secondaryCategory", headerName: "Secondary Category", hide: true, filterable: false },
    { field: "condition", headerName: "Condition" },
    { field: "conditionNote", headerName: "Condition Note", hide: true },
    { field: "brand", headerName: "Brand" },
    { field: "screenSize", headerName: "Screen Size" },
    { field: "processor", headerName: "Processor" },
    { field: "model", headerName: "Model" },
    { field: "releaseYear", headerName: "Release Year" },
    { field: "hardDriveCapacity", headerName: "Hard Drive Capacity", hide: true },
    { field: "color", headerName: "Color" },
    { field: "ram", headerName: "RAM" },
    { field: "storageType", headerName: "Storage Type", hide: true, filterable: false },
    { field: "gpu", headerName: "GPU" },
    { field: "processorSpeed", headerName: "Processor Speed" },
    { field: "operatingSystem", headerName: "Operating System", hide: true, filterable: false },
    { field: "maximumResolution", headerName: "Maximum Resolution", hide: true, filterable: false },
    { field: "itemHeight", headerName: "Item Height", hide: true, filterable: false },
    { field: "itemWidth", headerName: "Item Width", hide: true, filterable: false },
    { field: "itemLength", headerName: "Item Length", hide: true, filterable: false },
    { field: "itemWeight", headerName: "Item Weight", hide: true, filterable: false },
    { field: "shippingPolicyName", headerName: "Shipping Policy Name", hide: true, filterable: false },
    { field: "daysOnShelf", headerName: "Days On Shelf", hide: true },
    { field: "daysSincePurchase", headerName: "Days Since Purchase", hide: true },
    {
      field: "createdAt",
      headerName: "Created",
      ...dateTimeColDef,
      filterable: false,
      width: 200,
    },
    {
      field: "updatedAt",
      headerName: "Last Updated",
      ...dateTimeColDef,
      filterable: false,
      width: 200,
    },
    {
      field: "lastQuantitiesSyncedAt",
      headerName: "Last Quantities Sync",
      ...dateTimeColDef,
      filterable: false,
      width: 200,
    },
    {
      field: "lastFieldsSyncedAt",
      headerName: "Last Fields Sync",
      ...dateTimeColDef,
      filterable: false,
      width: 200,
    },
    {
      field: "photosUrl",
      headerName: "Photos",
      renderCell: (params: GridRenderCellParamsCustomType) => photosField(params.row),
      filterable: false,
      sortable: false,
      width: 200,
    },
  ].filter(Boolean);

  return (
    <RevivnApiDataGrid
      sx={{
        backgroundColor: "background.paper",
        minHeight: 600,
        height: "calc(100vh - 10em)",
      }}
      columns={columns}
      getDetailPanelHeight={() => "auto"}
      components={{ Toolbar: GridToolbar }}
      componentsProps={{
        filterPanel: { visible: false },
        toolbar: {
          printOptions: { disableToolbarButton: true },
          csvOptions: { disableToolbarButton: true, fileName: `product_catalogs_${Date.now()}` },
        },
      }}
      url={"product_catalogs"}
    />
  );
}
