import { Alert, Box, Grid, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { CaptureAngle, steps } from "components/Photobooth/photoBoothSteps";
import { WebcamComponent } from "components/WebcamComponent";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { useSwrInventory } from "hooks/useSwrInventory";
import { Navigate, Outlet, useMatch, useParams } from "react-router-dom";
import useSWR from "swr";
import { useSavedCameraConfiguration } from "pages/PhotoBooth/ConfigurationPage";

export function useMediaDevices(kind: MediaDeviceKind) {
  const { data, mutate } = useSWR("enumerate-devices", async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    return devices.filter((info) => info.kind === kind);
  });
  useEffect(() => {
    navigator.mediaDevices.ondevicechange = () => {
      mutate();
    };
    return function cleanup() {
      navigator.mediaDevices.ondevicechange = null;
    };
  }, [mutate]);

  return { mediaDevices: data };
}

export function PhotoBoothLayout() {
  const isConfiguration = useMatch("/resale-photo-booth/configuration");
  const isReview = useMatch(`/resale-photo-booth/:inventoryId/review`);
  const { inventoryId, photoSet } = useParams();

  const [sessionPhotos, setSessionPhotos] = useState<File[]>([]);
  const { data: inventory } = useSwrInventory(inventoryId);
  const { savedCameraConfiguration } = useSavedCameraConfiguration();
  const webcamRefs = useRef<Array<Webcam | null>>(Array(5).fill(null));

  const takePhotos = useCallback(async (angles: CaptureAngle[], filenames: string[]) => {
    const webcams = webcamRefs.current.filter((x): x is NonNullable<Webcam> => Boolean(x));

    if (webcams.length != 5) throw new Error("One or more Webcams are not ready");

    const files: File[] = [];
    let i = 0;
    for (const angle of angles) {
      const base64Image = webcams[angle].getScreenshot();
      if (!base64Image) return;
      const res = await fetch(base64Image);
      const blob = await res.blob();
      files.push(new File([blob], `${filenames[i++]}.jpg`, { type: "image/jpeg" }));
    }
    setSessionPhotos((p) => [...p, ...files]);
  }, []);

  const activeStep = inventoryId && photoSet ? Number(photoSet) : 0;

  useEffect(() => {
    if (activeStep == 1) setSessionPhotos([]);
  }, [activeStep]);

  if (savedCameraConfiguration.some((device) => device === null) && !isConfiguration) {
    return <Navigate replace={true} to="/resale-photo-booth/configuration" />;
  }

  return (
    <Box>
      <Box>
        <Stepper alternativeLabel activeStep={activeStep} sx={{ my: 4 }}>
          <Step completed={!!inventory}>
            <StepLabel>Scan Label</StepLabel>
          </Step>

          {steps.map((_value, index) => (
            <Step key={index} completed={activeStep > index || !!isReview}>
              <StepLabel>Set {index + 1}</StepLabel>
            </Step>
          ))}

          <Step last completed={!!isReview}>
            <StepLabel>Review</StepLabel>
          </Step>
        </Stepper>
        {inventory && inventory.resalePhotos.length > 0 && (
          <Alert severity="info" sx={{ marginY: 1 }}>
            {inventory.label} already has resale photos. Uploading a new set of photos will delete the existing set.
          </Alert>
        )}
      </Box>
      <Outlet context={{ inventory, takePhotos, sessionPhotos }} />
      <Grid
        container
        marginY={2}
        spacing={2}
        sx={isConfiguration ? {} : { position: "absolute", top: 0, left: 0, width: 0, height: 0 }}
      >
        {Object.keys(CaptureAngle)
          .slice(Object.keys(CaptureAngle).length / 2)
          .map((angle, index) => (
            <Grid item key={angle}>
              <Typography variant="h6">{angle}</Typography>
              <WebcamComponent
                device={savedCameraConfiguration[index]}
                ref={(el) => (webcamRefs.current[index] = el)}
              />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}
