import { useEffect, useRef, useState } from "react";
import { GridColType, GridEnrichedColDef } from "@mui/x-data-grid-pro";

function determineType(value: unknown): GridColType {
  if (typeof value === "string") return "string";
  if (typeof value === "number") return "number";
  if (typeof value === "boolean") return "boolean";
  if (value instanceof Date) return "date";
  return "string";
}

function extractDefaultColumns(data: unknown[]) {
  const firstRow = data[0];
  if (typeof firstRow !== "object" || !firstRow) return [];

  return Object.keys(firstRow).map((key) => ({
    field: key,
    headerName: key,
    width: 200,
    type: determineType(firstRow[key as keyof typeof firstRow]),
  }));
}

export function useLazyColumns(rows: unknown[]) {
  const [columns, setColumns] = useState<GridEnrichedColDef[]>([]);
  const columnsInitializedRef = useRef(false);

  useEffect(() => {
    if (!columnsInitializedRef.current && rows.length > 0) {
      columnsInitializedRef.current = true;
      setColumns(extractDefaultColumns(rows));
    }
  }, [rows]);

  return columns;
}
