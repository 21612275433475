import { useState, useEffect, useCallback } from "react";
import { useLocation, useParams } from "react-router-dom";
import { api } from "services/api.service";
import { useAlert } from "./useAlert";
import { Client } from "types/client";
import { OrganizationPlan } from "types/organizationPlan";
import { Location } from "types/location";
import { Contact } from "types/contact";

export default function useClients(defaultPageSize?: number) {
  const location = useLocation();
  const { search } = location;
  const params = useParams();
  const [clients, setClients] = useState<Client[]>([]);
  const [client, setClient] = useState<Client>();
  const [clientOrganizationPlan, setClientOrganizationPlan] = useState<OrganizationPlan>();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const { alertError, alertSuccess } = useAlert();
  const [isLoading, setLoading] = useState(false);
  const [clientLoading, setClientLoading] = useState(false);
  const [deactivateOpen, setDeactivateOpen] = useState(false);

  const getClient = useCallback(() => {
    setClientLoading(true);
    api
      .get(`/clients/${params.uuid}`)
      .then(({ data: { client: fetchedClient } }) => {
        setClient(fetchedClient);
      })
      .finally(() => {
        setClientLoading(false);
      });
  }, [params.uuid]);

  const deleteLocation = useCallback(
    (loc: Location) => {
      setClientLoading(true);
      api.delete(`/clients/${params.uuid}/locations/${loc.uuid}`).then(() => {
        getClient();
      });
    },
    [getClient, params.uuid],
  );

  const deleteContact = useCallback(
    (contact: Contact) => {
      setClientLoading(true);
      api.delete(`/clients/${params.uuid}/contacts/${contact.uuid}`).then(() => {
        getClient();
      });
    },
    [getClient, params.uuid],
  );

  const getClients = useCallback(() => {
    const searchHash = new URLSearchParams(search);
    if (!searchHash.get("page[size]") && defaultPageSize) {
      searchHash.append("page[size]", String(defaultPageSize));
    }
    api.get(`/clients?${searchHash.toString()}`).then(
      ({
        data: {
          clients: fetchedClients,
          meta: { totalPages: fetchedTotalPages, count: fetchedCount },
        },
      }) => {
        setClients(fetchedClients);
        setTotalPages(fetchedTotalPages);
        setCount(fetchedCount);
      },
    );
  }, [defaultPageSize, search]);

  type ClientProps = {
    name?: string;
    tier?: string;
    salesRep?: string;
    accountRep?: string;
  };

  type OrganizationPlanProps = {
    id?: string;
    uuid?: string;
    priceInCents?: number;
    organizationPlanFeatures: string[];
    planId: number;
    subscriptionStartedAt?: string;
    subscriptionExpiredAt?: string;
  };

  const createClient = (newClient: ClientProps) => {
    setLoading(true);
    api
      .post(`/clients`, {
        newClient,
      })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateClient = (selectedClient: ClientProps) => {
    setLoading(true);
    api
      .put(`/clients/${params.uuid}`, {
        client: selectedClient,
      })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateStatus = useCallback(
    (event: string, deactivationReason: string) => {
      return api
        .put(`clients/${params.uuid}/update_state`, { event: event, deactivationReason: deactivationReason })
        .then(() => {
          alertSuccess("Successfully deactivate this client");
          setDeactivateOpen(false);
          getClient();
        })
        .catch(({ response: { data } }) => {
          alertError(data.errors.toString());
        });
    },
    [alertError, alertSuccess, getClient, params.uuid],
  );

  const getClientOrganizationPlan = useCallback(() => {
    api.get(`/organizations/${params.uuid}/organization_plan`).then((response) => {
      setClientOrganizationPlan(response.data);
    });
  }, [params.uuid]);

  const updateOrganizationPlan = (organizationPlan: OrganizationPlanProps) => {
    api
      .put(`/organizations/${params.uuid}/organization_plan`, {
        organizationPlan,
      })
      .then((response) => {
        setClientOrganizationPlan(response.data);
      })
      .catch(() => {
        alertError("There was an error, please try again");
      });
  };

  useEffect(() => {
    if (!params.uuid) {
      getClients();
    }
  }, [getClients, params.uuid, search]);

  useEffect(() => {
    if (params.uuid) {
      getClient();
      getClientOrganizationPlan();
    }
  }, [params.uuid, location.pathname, getClient, getClientOrganizationPlan]);

  return {
    clients,
    client,
    clientOrganizationPlan,
    createClient,
    updateClient,
    updateOrganizationPlan,
    totalPages,
    count,
    isLoading,
    clientLoading,
    updateStatus,
    deactivateOpen,
    setDeactivateOpen,
    deleteLocation,
    deleteContact,
  };
}
