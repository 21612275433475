import { useCallback, useMemo, useState } from "react";
import { Blob, DirectUpload, DirectUploadDelegate } from "activestorage";
import { HOSTNAME } from "services/api.service";

const directUploadPrefix = HOSTNAME === "/" ? "" : HOSTNAME;
export type FileStorageService = "local" | "amazon" | "amazon_public" | "amazon_s3_stock";

type Props = {
  keyPrefix: string;
  service?: FileStorageService;
  useFilename?: boolean;
};

export interface DirectUploadOptions {
  service: FileStorageService;
  keyPrefix?: string;
  useFilename?: boolean;
  onProgress?: (progressPercent: number) => void;
}

export async function uploadFile(
  file: File,
  { keyPrefix = "", service, useFilename, onProgress }: DirectUploadOptions,
) {
  const directUpload = new DirectUpload(file, `${directUploadPrefix}/api/direct_uploads`, {
    directUploadWillCreateBlobWithXHR: (xhr) => {
      xhr.setRequestHeader("Key-Prefix", keyPrefix);
      xhr.setRequestHeader("Service", service);
      if (useFilename) xhr.setRequestHeader("Use-Filename", "true");
      if (onProgress) {
        xhr.upload.addEventListener("progress", (event) => {
          onProgress((event.loaded / event.total) * 10);
        });
      }
    },
    directUploadWillStoreFileWithXHR: (xhr) => {
      if (onProgress) {
        xhr.upload.addEventListener("progress", (event) => {
          onProgress(10 + (event.loaded / event.total) * 90);
        });
      }
    },
  });
  return new Promise<Blob>((resolve, reject) => {
    directUpload.create((error: Error, b: Blob) => {
      if (error) reject(error);
      else resolve(b);
    });
  });
}

export default function useDirectUpload({ keyPrefix, service = "amazon", useFilename = false }: Props) {
  const defaultService = process.env.REACT_APP_USE_PUBLIC_S3_SERVICE;
  const [blob, setBlob] = useState<Blob>();

  const delegate: DirectUploadDelegate = useMemo(
    () => ({
      directUploadWillCreateBlobWithXHR: (xhr: XMLHttpRequest) => {
        xhr.setRequestHeader("Key-Prefix", keyPrefix);
        xhr.setRequestHeader("Service", defaultService || service);
        if (useFilename) xhr.setRequestHeader("Use-Filename", "true");
      },
    }),
    [defaultService, keyPrefix, service, useFilename],
  );

  const reset = useCallback(() => {
    setBlob(undefined);
  }, [setBlob]);

  const upload = useCallback(
    (file: File) => {
      const directUpload = new DirectUpload(file, `${directUploadPrefix}/api/direct_uploads`, delegate);
      directUpload.create((error: Error, b: Blob) => {
        if (error) {
          console.error(error);
        } else if (setBlob) {
          setBlob(b);
        }
      });
    },
    [delegate],
  );

  const uploadSync = useCallback(
    (file: File) => {
      return new Promise<Blob>((resolve, reject) => {
        const directUpload = new DirectUpload(file, `${directUploadPrefix}/api/direct_uploads`, delegate);
        directUpload.create((error: Error, b: Blob) => {
          if (error) {
            console.error(error);
            reject(error);
          } else {
            resolve(b);
          }
        });
      });
    },
    [delegate],
  );

  return {
    blob,
    uploadSync,
    upload,
    reset,
  };
}
