import React from "react";
import { Box, Card, SxProps, Theme, Typography } from "@mui/material";
import { Filter } from "@mui/icons-material";

interface ImageThumbProps {
  src: string;
  overlay?: React.ReactNode;
  filename: string;
  actions?: React.ReactNode;
  sx?: SxProps<Theme>;
}

const Overlay = ({
  children,
  sx,
  className,
}: {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  className?: string;
}) => {
  return (
    <Box
      className={className}
      sx={{
        position: "absolute",
        backgroundColor: "rgba(255,255,255,.66)",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        borderRadius: "4px",
        flexDirection: "row",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export const ImageThumb = ({ src, overlay, filename, actions, sx }: ImageThumbProps) => {
  return (
    <Card
      sx={{
        padding: 1,
        width: 196,
        viewTransitionName: src.replace(/[/:._=]/g, ""),
        "&": { ".overlay": { display: "none" } },
        ":hover": { ".overlay": { display: "flex" } },
        ...sx,
      }}
    >
      <div style={{ position: "relative" }}>
        {overlay && (
          <Overlay sx={{ justifyContent: "center", alignItems: "center", backdropFilter: "grayscale(1)" }}>
            {overlay}
          </Overlay>
        )}
        {actions && (
          <Overlay className={"overlay"} sx={{ justifyContent: "end", alignItems: "start" }}>
            {actions}
          </Overlay>
        )}
        <img
          src={src}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: "auto",
            borderRadius: "4px",
          }}
        />
      </div>

      <Typography noWrap color="text.secondary">
        <Filter fontSize="inherit" /> {filename}
      </Typography>
    </Card>
  );
};
