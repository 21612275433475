export enum CaptureAngle {
  Front,
  Left,
  Right,
  Top,
  Hero,
}

interface StepConfig {
  photoCaptureCheck: string[];
  gifSource: string;
  color: string;
  batch: string;
  angles: CaptureAngle[];
  filenames: string[];
}

export const steps: StepConfig[] = [
  {
    photoCaptureCheck: [
      "Laptop closed",
      "Top facing up",
      "Parallel with alignment marker",
      "Centered on the alignment maker",
    ],
    gifSource: "/resale-photo-booth-step-1.webm",
    color: "#F2A02B",
    batch: "1",
    angles: [CaptureAngle.Front, CaptureAngle.Right, CaptureAngle.Left, CaptureAngle.Top, CaptureAngle.Hero],
    filenames: ["front_edge", "right_edge", "left_edge", "top_face", "top_angle"],
  },
  {
    photoCaptureCheck: [
      "Laptop open",
      "Screen is angled 130 degrees",
      "Parallel with alignment marker",
      "Centered on the alignment maker",
    ],
    gifSource: "/resale-photo-booth-step-2.webm",
    color: "#0183B7",
    batch: "2",
    angles: [CaptureAngle.Hero, CaptureAngle.Top],
    filenames: ["hero", "keyboard"],
  },
  {
    photoCaptureCheck: [
      "Laptop closed and bottom is face up",
      "Back edge is facing front",
      "Parallel with alignment marker",
      "Centered on the alignment maker",
    ],
    gifSource: "/resale-photo-booth-step-3.webm",
    color: "#D58031",
    batch: "3",
    angles: [CaptureAngle.Top, CaptureAngle.Front],
    filenames: ["bottom_face", "back_edge"],
  },
];
